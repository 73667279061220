import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  GET_ENTRUST,
  GET_ENTRUSTS,
  GET_ENTRUST_STATISTICS, GET_ENTRUST_ORDERS, MANUAL_DELETE_CONTRACT
} from "@/store/modules/entrust.module";

export default {
  computed: {
    ...mapGetters(['entrust', 'entrusts', 'entrustsMeta', 'entrustError', 'entrustStatistics', 'entrustOrders', 'entrustOrdersMeta'])
  },
  methods: {
    getEntrust(id, actions = {
      begin: ()=>{ this.$emit('loading'); this.showBusy('entrustBusy'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); this.hideBusy('entrustBusy'); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_ENTRUST, id),
      actions
      );
    },
    getEntrustOrder(id, page = 1, actions = {
      begin: ()=>{ this.$emit('loading'); this.showBusy('entrustBusy'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); this.hideBusy('entrustBusy'); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_ENTRUST_ORDERS, { id, page }),
      actions
      );
    },
    getEntrusts(page = 1, start_time = '', end_time = '', actions = {
      begin: ()=>{ this.showBusy('entrustBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('entrustBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_ENTRUSTS, {page, start_time, end_time}),
        actions
      );
    },
    getEntrustStatistics(start_time = '', end_time = '', actions = {
      begin: ()=>{ this.showBusy('entrustBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('entrustBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_ENTRUST_STATISTICS, {start_time, end_time}),
      actions
      );
    },
    manualDeleteContract(id, actions = {
      begin: ()=>{ this.$emit('loading'); this.showBusy('entrustBusy'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); this.hideBusy('entrustBusy'); },
    }){
      return mixinPromise(
      this.$store.dispatch(MANUAL_DELETE_CONTRACT, id),
      actions
      );
    },
  }
}
